#main-form {
    border: 1px solid #dcdcdc;
}

.c-input__input.is-error~.c-input__addon::before, .c-input__input.is-success~.c-input__addon::before, .c-input__input.is-error~.c-input__state-icon::before, .c-input__input.is-success~.c-input__state-icon::before {
    display: none;
}

.c-input__input.is-success, .c-input__input.is-success~.c-input__addon, .c-input__input.is-success~.c-input__state-icon {
    box-shadow: none;
}

.c-input__input.is-success, .c-input__input.is-success~.c-input__addon {
    border-right: none;
}

.c-input__input.is-success~.c-input__state-icon {
    border-left: none;
}

.c-input.is-success {
    box-shadow: 0 0 0 1px #038364;
}