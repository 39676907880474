.recaptcha-div {
    margin-bottom: 20px;
}

.recaptcha-div>div {
    width: fit-content;
    float: left;
}

.button-disabled {
    border: 1px solid #d8d8d8;
    color: #a8adb3;
    background-color: #d8d8d8;
    pointer-events: none;
}